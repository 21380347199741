.error-head {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#6576ff, #2c3782);
  background-clip: text;
  -webkit-background-clip: text;
  opacity: 0.9;
}

.error-btn {
  background: -webkit-linear-gradient(#6576ff, #2c3782);
  margin: 1em 0em;
}
