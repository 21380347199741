@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Inter";
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #1f2a40;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    to top,
    #3e4394,
    #303e7f,
    #26386a,
    #213155,
    #1f2a40
  );
  border-radius: 5px;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #6b729e;
}

#tags-default-listbox {
  z-index: 999999 !important ;
}

.unread-row {
  background-color: grey; /* Change this to your desired color */
  color: white; /* Change text color for better contrast if needed */
}

.apexcharts-menu {
  background-color: #a9a9a9 !important;
  border: 0px;
}

.swal2-popup-dark {
  background-color: #333 !important; /* Dark background */
  color: #fff !important; /* White text */
  border: 6px solid #1f2a40 !important;
}

.swal2-title-dark {
  color: #fff !important; /* Title text in white */
}

.swal2-content-dark {
  color: #ccc !important; /* Light gray content text */
}

.swal2-confirm-dark {
  background-color: #4cceac !important; /* Confirm button green */
  color: black !important; /* Confirm button text in white */
}

.swal2-cancel-dark {
  background-color: #424242 !important; /* Cancel button red */
  color: white !important; /* Cancel button text in white */
}
